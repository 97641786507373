<template>
  <div>
    <Loading :loading="isLoading"></Loading>
    <v-container class="mw-1150">
      <BreadCrumbs :items="items"></BreadCrumbs>
      <div class="text-center my-10">
        <div
          class="font-40 font-w-600 text-black"
          v-html="data.name_events_news"
        ></div>
      </div>
      <div class="d-flex justify-space-between my-3 font-13 text-black">
        <div>
          <i class="bi bi-book"></i> ผู้เขียน {{ data.author_name_events }}
        </div>
        <div>
          <i class="bi bi-calendar-minus"></i>
          {{ data.date_events | moment("DD/MM/YYYY") }}
        </div>
      </div>
      <div class="media-article" v-html="data.content"></div>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import Loading from "../../components/Loading.vue";
import { mapState } from "vuex";
import BreadCrumbs from "../../components/BreadCrumbs.vue";

export default {
  components: {
    Loading,
    BreadCrumbs,
  },
  computed: {
    ...mapState({
      eventAndNews: (state) => state.EventAndNews.eventAndNews,
    }),
  },
  data() {
    return {
      moment: moment,
      isLoading: false,
      data: {
        author_name_events: "",
      },
      items: [
        {
          text: "หน้าแรก",
          disabled: false,
          href: "/",
        },
        {
          text: "กิจกรรมและข่าวสาร",
          disabled: false,
          href: "/event-news",
        },
      ],
    };
  },
  async created() {
    const eventId = this.$route.params.id;
    this.isLoading = true;
    if (eventId) {
      await this.$store.dispatch("EventAndNews/show", eventId).then((res) => {
        this.data = res;
        this.items.push({
          text: this.data.name_events_news,
          disabled: true,
        });
      });
    }
    this.isLoading = false;
  },
};
</script>

<style></style>
